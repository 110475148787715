import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Stack from "react-bootstrap/Stack";

export default function Header() {
	return (
		<Navbar className="mb-3 sticky-top" variant="dark" bg="dark" expand="lg">
			<Container>
				<Navbar.Brand as={Link} to="/">
					<img
						src="/img/logo.svg"
						height="45"
						className="d-inline-block align-top"
						alt="Emergency Hamburg"
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse>
					<Nav className="me-auto">
						<Nav.Link
							href="https://www.roblox.com/share?code=4e2ad01d4161cf4e81590f8047941018&type=ExperienceAffiliate"
							target="_blank"
							className="fw-bold">
							<i className="bi bi-controller" /> Game
						</Nav.Link>
						<Nav.Link
							href="https://wiki.emergency-hamburg.com/"
							target="_blank"
							className="fw-bold">
							<i className="bi bi-info-circle" /> Wiki
						</Nav.Link>
						<Nav.Link className="fw-bold" onClick={() => window.chatThing.toggle()}>
							<i className="bi bi-question-circle" /> Support
						</Nav.Link>
						<Nav.Link as={Link} to="/private-servers" className="fw-bold">
							<i className="bi bi-hdd-stack" /> Private Servers
						</Nav.Link>

						<NavDropdown title="Forms" className="fw-bold">
							<NavDropdown.Item as={Link} to="/player-report">
								<i className="bi bi-flag-fill" /> Player Report
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/unban-request">
								<i className="bi bi-hammer" /> Unban Request
							</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item
								as={Link}
								to="https://form.asana.com/?k=y4JszbkhwFHuIjbZjTXg_Q&d=1208278475954295"
								target="_blank">
								<i className="bi bi-bug-fill" /> Game Bug Report
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="https://forms.gle/ehZQaYUTn9Mq8gv87" target="_blank">
								<i className="bi bi-translate" /> Translation Bug Report
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="https://appeal.gg/emh" target="_blank">
								<i className="bi bi-discord" /> Discord Ban Appeal
							</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item as={Link} to="https://forms.gle/SyBwE3MBwVQxs8cC8" target="_blank">
								<i className="bi bi-person-bounding-box" /> Developer Application
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="https://forms.gle/LNNX3EGfvA72HGeSA" target="_blank">
								<i className="bi bi-receipt" /> Ingame Product Loss
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="https://forms.gle/zvJk3Syu6CQH375i6" target="_blank">
								<i className="bi bi-shield-fill-exclamation" /> Staff Report
							</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="Other" className="fw-bold">
							<NavDropdown.Item as={Link} to="/imprint">
								Imprint
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/terms-of-service">
								Terms of Service
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/privacy-policy">
								Privacy Policy
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
					<Nav>
						<Nav.Item>
							<Stack direction="horizontal" gap={2}>
								<a
									href="https://www.discord.gg/emh"
									target="_blank"
									rel="noreferrer"
									className="link-light opacity-75">
									<i className="bi bi-discord" />
								</a>
								<a
									href="https://www.tiktok.com/@emergencyhamburg"
									target="_blank"
									rel="noreferrer"
									className="link-light opacity-75">
									<i className="bi bi-tiktok" />
								</a>
								<a
									href="https://x.com/emerghamburg"
									target="_blank"
									rel="noreferrer"
									className="link-light opacity-75">
									<i className="bi bi-twitter-x" />
								</a>
								<a
									href="https://www.twitch.tv/emergencyhamburg"
									target="_blank"
									rel="noreferrer"
									className="link-light opacity-75">
									<i className="bi bi-twitch" />
								</a>
							</Stack>
						</Nav.Item>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
